<template>
  <div class="Employee">
    <!--Welcome to Page employee page {{personalDetails}}-->
    <h1 align="center">Employee Details</h1>
    <v-container class="containerclass">
      <!--<v-snackbar v-model="snackbar">
        {{ snackbartext }}
        <template v-slot:action="{ attrs }">
          <v-btn color="success" text v-bind="attrs" @click="snackbar = false"> Okay </v-btn>
        </template>
      </v-snackbar>-->

      <!--  for user notification once created and updated profile -->
      <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>
      <v-form>
        <div class="text-center d-flex pb-4">
          <v-btn v-if="!expandall" @click="all"> Expand All </v-btn>

          <v-btn v-if="expandall" @click="none"> Collapse </v-btn>
        </div>
        <v-expansion-panels focusable multiple :value="expanded">
          <v-expansion-panel>
            <v-expansion-panel-header color="#16283F" class="white--text"> OFFICIAL DETAILS</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout row wrap>
                <v-flex xs6 sm4 md2 lg2 pl-1>
                  <h4>ID</h4>
                  <v-text-field v-if="hrrole" v-model="officialDetails.empid" required> </v-text-field>
                  <div v-else>{{ officialDetails.empid }}</div>
                </v-flex>
                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Name</h4>
                  <v-text-field v-model="officialDetails.name" :rules="charRules" required> </v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md4 lg4 pl-1>
                  <h4>Email</h4>
                  <v-text-field v-if="hrrole" v-model="officialDetails.email"> </v-text-field>
                  <div v-else>{{ officialDetails.email }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Manager Name</h4>
                  <v-combobox
                    v-if="hrrole"
                    v-model="officialDetails.mgrname"
                    :rules="charRules"
                    :items="mgrnames"
                    @input="selectedmgrnames"
                    required
                  ></v-combobox>
                  <div v-else>{{ officialDetails.mgrname }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Manager Email</h4>
                  <v-combobox
                    v-if="hrrole"
                    v-model="officialDetails.mgremail"
                    :rules="emailRules"
                    :items="mgremails"
                    @input="selectedmgremails"
                    required
                  ></v-combobox>
                  <div v-else>{{ officialDetails.mgremail }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Client Manager Name</h4>
                  <v-combobox
                    v-if="hrrole"
                    v-model="officialDetails.clientmgrname"
                    :rules="charRules"
                    :items="reviewersnames"
                    @input="selectedreviewersnames"
                    required
                  ></v-combobox>
                  <div v-else>{{ officialDetails.clientmgrname }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Client Manager Email</h4>
                  <v-combobox
                    v-if="hrrole"
                    v-model="officialDetails.clientmgremail"
                    :rules="emailRules"
                    :items="reviewersemails"
                    @input="selectedreviewersemails"
                    required
                  ></v-combobox>
                  <div v-else>{{ officialDetails.clientmgremail }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>DOJoin</h4>
                  <DatePicker
                    :menu="menuDOJ"
                    :datevalue="officialDetails.empDOJ"
                    :datelabeltext="'Joining date'"
                    @dateassinged="officialDetails.empDOJ = $event"
                  />
                  <div v-if="hrrole">
                    <!--  <v-menu
                      ref="menu"
                      v-model="menuDOJ"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="officialDetails.empDOJ"
                          label="Joining date"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="officialDetails.empDOJ"
                        day
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="savedoj"
                      ></v-date-picker>
                    </v-menu>-->
                  </div>
                  <div v-else>{{ officialDetails.empDOJ }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>DOExit</h4>
                  <div v-if="hrrole">
                    <DatePicker
                      :menu="menuDOE"
                      :datevalue="officialDetails.empDOE"
                      :datelabeltext="'Exit date'"
                      @dateassinged="officialDetails.empDOE = $event"
                    />
                    <!--  <v-menu ref="menu" v-model="menuDOE" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px" >
                             <template v-slot:activator="{ on, attrs }">
                             <v-text-field  v-model="officialDetails.empDOE" label="Exit date"  prepend-icon="event" readonly v-bind="attrs" v-on="on" ></v-text-field>
                             </template>
                              <v-date-picker ref="picker" v-model="officialDetails.empDOE" day :max="new Date().toISOString().substr(0, 10)" min="1950-01-01" @change="save" ></v-date-picker>
                            </v-menu>-->
                  </div>
                  <div v-else>{{ officialDetails.empDOE }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Department</h4>
                  <v-text-field v-if="hrrole" v-model="officialDetails.deptName"> </v-text-field>
                  <div v-else>{{ officialDetails.deptName }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Grade</h4>
                  <v-text-field v-if="hrrole" v-model="officialDetails.grade"> </v-text-field>
                  <div v-else>{{ officialDetails.grade }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Designation</h4>
                  <v-text-field v-if="hrrole" v-model="officialDetails.designation"> </v-text-field>
                  <div v-else>{{ officialDetails.designation }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Client</h4>
                  <v-text-field v-if="hrrole" v-model="officialDetails.client"> </v-text-field>
                  <div v-else>{{ officialDetails.client }}</div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <!-- <v-text-field v-if="hrrole" v-model="officialDetails.onsite"> </v-text-field>-->
                  <v-combobox
                    v-if="hrrole"
                    v-model="officialDetails.onsiteyesno"
                    :items="onsiteyesno"
                    label="Onsite"
                  ></v-combobox>
                  <div v-else>
                    <h4>Onsite</h4>
                    {{ officialDetails.onsite }}
                  </div>
                </v-flex>

                <v-flex xs6 sm4 md3 lg3 pl-1>
                  <h4>Onsite Country</h4>
                  <v-text-field v-if="hrrole" v-model="officialDetails.onsitecountry"> </v-text-field>
                  <div v-else>{{ officialDetails.onsitecountry }}</div>
                </v-flex>

                <v-flex v-if="hrrole" xs6 sm4 md3 lg3 pl-1>
                  <v-btn large @click="saveemp()"> Update </v-btn>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <template v-slot:[`personalDetails.mobileNumber`]="{ item }">
              {{ convertmobile(item.personalDetails.mobileNumber) }}
            </template>
            <v-expansion-panel-header color="#16283F" class="white--text"> PERSONAL DETAILS</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout row wrap>
                <v-flex xs6 sm4 md4 lg3 pl-1>
                  <v-text-field
                    v-model="personalDetails.fullName"
                    label="Full Name"
                    :rules="charRules"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm4 md4 lg3 pl-1>
                  <v-text-field v-model="personalDetails.email" label="Alternate Email" required></v-text-field>
                </v-flex>

                <v-flex xs6 sm4 md2 lg3 pl-1>
                  <v-text-field
                    v-model="personalDetails.mobileNumber"
                    background-color="gray"
                    label="*Mobile Number"
                    :rules="numberRules"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm4 md2 lg3 pl-1>
                  <v-select v-model="personalDetails.gender" :items="gender" label="*Gender" required> </v-select>
                </v-flex>

                <v-flex xs12 sm12 md6 lg4 pl-1>
                  <v-textarea
                    filled
                    auto-grow
                    label="Current Address"
                    v-model="personalDetails.currentAddress"
                    required
                    rows="2"
                    row-height="20"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm12 md6 lg4 pl-1>
                  <v-textarea
                    filled
                    auto-grow
                    label="Permanent Address"
                    v-model="personalDetails.permanentAddress"
                    required
                    rows="2"
                    row-height="20"
                  ></v-textarea>
                </v-flex>

                <v-flex xs6 sm4 md2 lg3 pl-1>
                  <DatePicker
                    :menu="menuDOB"
                    :datevalue="personalDetails.empDOB"
                    :datelabeltext="'Birth date'"
                    @dateassinged="personalDetails.empDOB = $event"
                  />
                  <!--  <v-menu
                    ref="menu"
                    v-model="menuDOB"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="personalDetails.empDOB"
                        label="Birth Date"
                        required
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="personalDetails.empDOB"
                      day
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>-->
                </v-flex>

                <v-flex xs6 sm4 md2 lg2 pl-1>
                  <v-text-field
                    v-model="personalDetails.alternateContact"
                    label="Alternate Contact"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm4 md2 lg2 pl-1>
                  <v-select v-model="personalDetails.matrialStatus" :items="matrial" label="Marital Status" required>
                  </v-select>
                </v-flex>

                <v-flex xs4 sm4 md2 lg2 pl-1>
                  <v-combobox
                    v-model="personalDetails.bloodGroup"
                    :items="bloodGroups"
                    label="Blood Group"
                    required
                  ></v-combobox>
                </v-flex>

                <v-flex xs4 sm4 md3 lg3 pl-1>
                  <DatePicker
                    :menu="menuWed"
                    :datevalue="personalDetails.weddingAnniversary"
                    :datelabeltext="'Wedding Anniversary'"
                    @dateassinged="personalDetails.weddingAnniversary = $event"
                  />
                  <!--<v-menu
                    ref="menu"
                    v-model="menuWed"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="personalDetails.weddingAnniversary"
                        label="Wedding Anniversary"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="personalDetails.weddingAnniversary"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>-->
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header color="#16283F" class="white--text"> DOCUMENT DETAILS</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout row wrap>
                <v-flex xs6 sm6 md4 lg4 pl-1>
                  <v-text-field label="Aadhar Number" v-model="documentDetails.addharNumber"></v-text-field>
                </v-flex>

                <v-flex xs6 sm6 md4 lg4 pl-1>
                  <v-text-field label="PAN Number" v-model="documentDetails.panNumber"></v-text-field>
                </v-flex>

                <v-flex xs6 sm6 md4 lg4 pl-1>
                  <v-text-field label="PF-UANumber " v-model="documentDetails.pfUAN"></v-text-field>
                </v-flex>

                <v-flex xs6 sm6 md4 lg4 pl-1>
                  <v-text-field label="Passport Number" v-model="documentDetails.passportNumber"></v-text-field>
                </v-flex>

                <v-flex xs6 sm6 md4 lg4 pl-1>
                  <DatePicker
                    :menu="menu_ppexp"
                    :datevalue="documentDetails.passportExpiryDate"
                    :datelabeltext="'Passport Expiry Date'"
                    @dateassinged="documentDetails.passportExpiryDate = $event"
                  />
                  <!-- <v-menu
                    ref="menu"
                    v-model="menu_ppexp"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="documentDetails.passportExpiryDate"
                        label="Passport Expiry Date"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="documentDetails.passportExpiryDate"
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>-->
                </v-flex>

                <v-flex xs12 sm12 md12 lg12 pl-2>
                  <div>
                    <Upload
                      ref="Documents"
                      :attachments="documentDetails.images"
                      :storagepath="storagePath"
                      :uploadname="`Documents`"
                      :disabled="disabled"
                      @addAttachment="addAttachment"
                      @removeAttachment="removeAttachment"
                    />
                  </div>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="#16283F" class="white--text"> EMERGENCY DETAILS</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout row wrap>
                <v-flex xs6 sm4 md3 lg2 pl-1>
                  <v-text-field
                    label="Contact Name "
                    v-model="emergencyDetails.contactName"
                    :rules="charRules"
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm4 md3 lg2 pl-1>
                  <v-text-field
                    label="Contact Number "
                    v-model="emergencyDetails.contactNumber"
                    :rules="numberRules"
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm4 md3 lg2 pl-1>
                  <v-combobox
                    v-model="emergencyDetails.contactRelation"
                    :items="relations"
                    label="Relationship"
                    required
                  ></v-combobox>
                </v-flex>

                <v-flex xs6 sm4 md3 lg2 pl-1>
                  <v-text-field label="Alternate Contact" v-model="emergencyDetails.alternateContact"></v-text-field>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="#16283F" class="white--text"> FAMILY DETAILS</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout row wrap>
                <v-flex xs6 sm6 md3 lg3 pl-1>
                  <v-text-field label="Father's Name " v-model="familyDetails.father.name"></v-text-field>
                </v-flex>

                <v-flex xs6 sm6 md3 lg3 pl-1>
                  <DatePicker
                    :menu="menu_fatherDOB"
                    :datevalue="familyDetails.father.DOfB"
                    :datelabeltext="'Father\'s DOB'"
                    @dateassinged="familyDetails.father.DOfB = $event"
                  />
                  <!-- <v-menu
                    ref="menu"
                    v-model="menu_fatherDOB"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="familyDetails.father.DOfB"
                        label="Father's DOB"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="familyDetails.father.DOfB" @change="save"></v-date-picker>
                  </v-menu>-->
                </v-flex>

                <v-flex xs6 sm6 md3 lg3 pl-1>
                  <v-text-field label="Mother's Name " v-model="familyDetails.mother.name"></v-text-field>
                </v-flex>

                <v-flex xs6 sm6 md3 lg3 pl-1>
                  <DatePicker
                    :menu="menu_motherDOB"
                    :datevalue="familyDetails.mother.DOfB"
                    :datelabeltext="'Mother\'s DOB'"
                    @dateassinged="familyDetails.mother.DOfB = $event"
                  />
                  <!--<v-menu
                    ref="menu"
                    v-model="menu_motherDOB"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="familyDetails.mother.DOfB"
                        label="Mother's DOB"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="familyDetails.mother.DOfB" @change="save"></v-date-picker>
                  </v-menu>-->
                </v-flex>

                <v-flex xs6 sm6 md3 lg3 pl-1>
                  <v-text-field label="Spouse Name " v-model="familyDetails.spouse.name"></v-text-field>
                </v-flex>

                <v-flex xs6 sm6 md3 lg3 pl-1>
                  <DatePicker
                    :menu="menu_spouseDOB"
                    :datevalue="familyDetails.spouse.DOfB"
                    :datelabeltext="'Spouse DOB'"
                    @dateassinged="familyDetails.spouse.DOfB = $event"
                  />
                  <!--<v-menu
                    ref="menu"
                    v-model="menu_spouseDOB"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="familyDetails.spouse.DOfB"
                        label="Spouse DOB"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="familyDetails.spouse.DOfB" @change="save"></v-date-picker>
                  </v-menu>-->
                </v-flex>

                <v-flex xs6 sm6 md3 lg3 pl-1>
                  <v-text-field label="Child1 Name " v-model="familyDetails.child1.name"></v-text-field>
                </v-flex>

                <v-flex xs2 sm2 md1 lg1 pl-1>
                  <v-select v-model="familyDetails.child1.gender" :items="gender" label="Gender"> </v-select>
                </v-flex>

                <v-flex xs4 sm4 md2 lg2 pl-1>
                  <DatePicker
                    :menu="menu_child1DOB"
                    :datevalue="familyDetails.child1.DOfB"
                    :datelabeltext="'Child1 DOB'"
                    @dateassinged="familyDetails.child1.DOfB = $event"
                  />
                  <!--<v-menu
                    ref="menu"
                    v-model="menu_child1DOB"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="familyDetails.child1.DOfB"
                        label="Child1 DOB"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="familyDetails.child1.DOfB" @change="save"></v-date-picker>
                  </v-menu>-->
                </v-flex>

                <v-flex xs6 sm6 md3 lg3 pl-1>
                  <v-text-field label="Child2 Name " v-model="familyDetails.child2.name"></v-text-field>
                </v-flex>

                <v-flex xs2 sm2 md1 lg1 pl-1>
                  <v-select v-model="familyDetails.child2.gender" :items="gender" label="Gender"> </v-select>
                </v-flex>

                <v-flex xs4 sm4 md2 lg2 pl-1>
                  <DatePicker
                    :menu="menu_child2DOB"
                    :datevalue="familyDetails.child2.DOfB"
                    :datelabeltext="'Child2 DOB'"
                    @dateassinged="familyDetails.child2.DOfB = $event"
                  />
                  <!-- <v-menu
                    ref="menu"
                    v-model="menu_child2DOB"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="familyDetails.child2.DOfB"
                        label="Child2 DOB"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="familyDetails.child2.DOfB" @change="save"></v-date-picker>
                  </v-menu>-->
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="mt-1">
          <v-layout v-if="loaded">
            <v-row justify="center">
              <v-flex align="center" xs12 sm12 md12 lg12 pl-3>
                <div v-if="hrrole">
                  <v-btn text class="savecolor ml-2" v-if="!spinner  " @click="approveemp()"> Approve </v-btn>
                  <v-btn text class="cancelcolor ml-2" v-if="!spinner " @click="rejectemp()"> Reject </v-btn>
                  <Spinner v-if="spinner" />

                  <!--<v-btn large :to="backmenu"> Back </v-btn>-->
                </div>
                <div v-else>
                  <v-btn text v-if="!spinner" textclass="savecolor ml-2" @click="saveemp()"> Save </v-btn>
                  <Spinner v-if="spinner" />
                  <!-- <v-btn text class="cancelcolor ml-2" @click="cancleemp()"> Cancel </v-btn>-->
                  <!--<v-btn large  class="ml-2" @click="fillSample()"> FillSample </v-btn>-->
                </div>
              </v-flex>
            </v-row>
          </v-layout>
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import {
  firebaseApp,
  empRef,
  deleteRef,
  usersRef,
  emailRef,
  leavesummariesRef,
  configgenRef,
  reviewersRef,
  PDPRef,
  recruitmentRef,
} from "../fb.js"
import { emailMixin } from "@/mixins/emailMixin.js"
import Upload from "../components/common/Upload.vue"
import { updateemailMixin } from "@/mixins/updateemailMixin.js"

import { convertDates } from "@/mixins/convertDates.js"
import Snackbar from "@/components/common/snackbar.vue"

import Spinner from "@/components/common/spinner.vue"
import DatePicker from "@/components/common/Datepicker.vue"

export default {
  name: "emplolyee",
  mixins: [emailMixin, convertDates, updateemailMixin],
  props: ["roles"], // will have complete record of user-role table.
  components: {
    Upload,
    Snackbar,

    Spinner,

    DatePicker,
  },
  data: () => ({
    expanded: [0],
    expandall: false,
    date: null,
    menuDOJ: false,
    menuDOE: false,
    menuDOB: false, // date picker for DOD
    menuWed: false, // date picker for Wedding in add itemm
    menu_ppexp: false,
    menu_fatherDOB: false,
    menu_motherDOB: false,
    menu_spouseDOB: false,
    menu_child1DOB: false,
    menu_child2DOB: false,
    disabled: false,
    editing: false,
    approvalStatus: "pending",

    charRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 4) || "Name should be atleast 4 characters",
      (v) => /^[A-Za-z\s]+$/.test(v) || "Only Alphabets and Spaces allowed",
    ],
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],

    numberRules: [
      (v) => !!v || "Input is required",
      (v) => (v && v.length >= 10) || "Should be atleast 10 Numbers",
      (v) => /^[0-9\s]+$/.test(v) || "Only numbers",
    ],

    gender: ["Female", "Male"],
    matrial: ["Single", "Married"],
    onsiteyesno: ["YES", "NO"],
    bloodGroups: ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-", "A1+", "A1-", "B1+", "B1-"],
    relations: ["Father", "Mother", "Husband", "Wife", "Brother", "Sister", "Guardian", "Relavtive", "Friend"],
    mgrnames: [],
    mgremails: [],
    managers: [],
    reviewers: [],
    reviewersnames: [],
    reviewersemails: [],
    catchfamilyDetails: "",
    catchdocumentDetails: "",
    catchemergencyDetails: "",
    catchpersonalDetails: "",

    familyDetails: {
      father: { name: "", DOfB: "" },
      mother: { name: "", DOfB: "" },
      spouse: { name: "", DOfB: "" },
      child1: { name: "", DOfB: "", gender: "" },
      child2: { name: "", DOfB: "", gender: "" },
    },
    officialDetails: {
      empid: "",
      email: "",
      name: "",
      mgrname: "",
      mgremail: "",
      mgrid: "",
      clientmgrname: "",
      clientmgremail: "",
      clientmgrid: "",
      empDOJ: "",
      empDOE: "",
      deptName: "",
      grade: "",
      designation: "",
      client: "",
      onsite: "",
      onsitecountry: "",
      empDOR: "",
    },

    personalDetails: {
      email: "",
      fullName: "",
      mobileNumber: "",
      gender: "",
      empDOB: "",
      currentAddress: "",
      alternateContact: "",
      permanentAddress: "",
      matrialStatus: "",
      bloodGroup: "",
      weddingAnniversary: "",
    },
    emergencyDetails: {
      contactName: "",
      contactNumber: "",
      contactRelation: "",
      alternateContact: "",
    },
    documentDetails: {
      passportNumber: "",
      passportExpiryDate: "",
      addharNumber: "",
      panNumber: "",
      pfUAN: "",
      images: [],
    },

    role: "",

    mailmsg: {
      to: "",
      subject: "",
      message: "",
    },

    uid: "",
    hrrole: false,

    FirstName: "",
    noofficialemail: false,
    domain: "",
    password: "",
    snackbar: false,
    snackbartext: "",
    spinner: false,
    loaded:false,
    officialemail:'',
    //roles:'',
  }),
  async created() {
    if (this.roles) {
      console.log("inside created roles", this.roles)
      this.parsevalues(this.roles)
    } else {
      console.log("inside created stores", this.roles)
      this.parsevalues(await this.$store.state.userProfile)
    }
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"))
    },
  },
  computed: {
    storagePath() {
      var testrportstoreagepath = "empdocs/" + this.domain + "_" + this.officialDetails.empid + "_"
      return testrportstoreagepath
    },
  },
  methods: {
    parsevalues(empvalues) {
      console.log("inside Parsevalues", empvalues)
      this.officialDetails.empid = empvalues.EmpID
      this.domain = empvalues.domain
      if (empvalues.email.includes(this.domain)) {
        this.officialDetails.email = empvalues.email
      } else {
        this.personalDetails.email = empvalues.email
        this.noofficialemail = true
        this.officialDetails.email = null
      }
      this.officialDetails.name = empvalues.name
      this.officialDetails.mgrname = empvalues.mgrname
      this.officialDetails.mgremail = empvalues.mgremail

      this.status = empvalues.status
      this.role = empvalues.role
      console.log("this.role", this.role)
      if (this.role) {
        console.log("isnide this.role")
        if (this.role == "hradmin" || this.role == "sysadmin" || this.role.hradmin || this.role.sysadmin) {
          this.hrrole = true
        }
      }
      console.log("this.hrrole", this.hrrole)
      this.queryemp()
    },
    // with all values as true
    all() {
      this.expanded = [0, 1, 2, 3, 4]
      this.expandall = true
    },
    // Reset the panel
    none() {
      this.expanded = []
      this.expandall = false
    },
    /*fillSample()
    {
       
        this.personalDetails.fullName='EMP Full Name';
        this.personalDetails.mobileNumber='0091123456789',
        this.personalDetails.gender="Male";
        this.personalDetails.empDOB="2020-01-01";
        this.personalDetails.currentAddress="8TH floor,Pruva Primas ,Rajiv Gandi Salai ,Chennai-600001"
        this.personalDetails.alternateContact="00910987654321"
        this.personalDetails.permanentAddress="8TH floor,Pruva Primas ,Rajiv Gandi Salai ,Chennai-600001"
        this.personalDetails.matrialStatus="Married"
        this.personalDetails.bloodGroup="B+";
         this.personalDetails.weddingAnniversary="2020-01-01";
              
        this.emergencyDetails.contactName='EMP Emergency contact name';
        this.emergencyDetails.contactNumber="00910987654321"
        this.emergencyDetails.contactRelation="WIFE"
        this.emergencyDetails.alternateContact="00914412345678"
           
        this.documentDetails.passportNumber='PPN123456',
        this.documentDetails.passportExpiryDate="2020-01-01";
        this.documentDetails.addharNumber='12345678901234',
        this.documentDetails.panNumber='PAN123456',
        this.documentDetails.pfUAN='PFUAN123',
        this.documentDetails.images=[];

        this.familyDetails.father.name='EMP Father Name';
        this.familyDetails.mother.name='EMP Mother Name';
        this.familyDetails.spouse.name='EMP Spouse Name';
        this.familyDetails.child1.name='EMP Child1 Name';
        this.familyDetails.child2.name='EMP Child2 Name';

        this.familyDetails.child1.gender="Male";
        this.familyDetails.child2.gender="FeMale";
      
        this.familyDetails.father.DOfB="2020-01-01";
        this.familyDetails.mother.DOfB="2020-01-01";
        this.familyDetails.spouse.DOfB="2020-01-01";
        this.familyDetails.child1.DOfB="2020-01-01";
        this.familyDetails.child2.DOfB="2020-01-01";

      },*/

    save(date) {
      this.$refs.menu.save(date)
    },
    savedoj(date) {
      this.$refs.menu.save(date)
      this.empleavedateailsupdate(date)
    },
    async queryemp() {
      var queryresult = []

      console.log("inside queryemp", this.domain, this.officialDetails.empid)
      try {
        queryresult = await empRef
          .where("domain", "==", this.domain)
          .where("empid", "==", this.officialDetails.empid)
          .get()
        console.log("after  query", queryresult.docs.length)
        if (queryresult.docs.length == 1) {
          this.uid = queryresult.docs[0].id
          this.officialDetails = queryresult.docs[0].data().officialDetails
          this.personalDetails = queryresult.docs[0].data().personalDetails
          this.documentDetails = queryresult.docs[0].data().documentDetails
          this.emergencyDetails = queryresult.docs[0].data().emergencyDetails
          this.familyDetails = queryresult.docs[0].data().familyDetails

          this.officialemail=this.officialDetails.email;

          /* this is to check the images array after query if it is null then set to as null array inorder to handle images.push method.
              it thows error in images.push method while it is null.*/
          if (!this.documentDetails.images) {
            this.documentDetails.images = []
          }
          /* commented as the hr can update the emp email at any time */
          /*if (queryresult.docs[0].data().approvalStatus == "approved" && this.hrrole) {
            this.hrrole = false
          }*/
        } else {
          if (this.hrrole) {
            this.hrrole = false
          }
        }
        if (this.hrrole) {
          //query the names only if hrrole is true to assign the mgrnames
          queryresult = []
          this.mgrnames = []
          queryresult = await empRef.where("domain", "==", this.domain).get()
          for (var i = 0; i < queryresult.docs.length; ++i) {
            this.managers.push({
              name: queryresult.docs[i].data().name,
              email: queryresult.docs[i].data().email,
              id: queryresult.docs[i].id,
            })
            //  this.mgrnames.push(queryresult.docs[i].data().name)
            //this.mgremails.push(queryresult.docs[i].data().email)
          }
          this.mgrnames = this.managers.map((item) => {
            return item.name
          })
          this.mgremails = this.managers.map((item) => {
            return item.email
          })
          
          ;(this.reviewers = []),
            await reviewersRef
              .doc(this.domain)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  this.reviewers = doc.data().reviewers
                  this.reviewersnames = this.reviewers.map((item) => {
                    return item.name
                  })
                  this.reviewersemails = this.reviewers.map((item) => {
                    return item.email
                  })
                }
              })
        }
        this.loaded=true
      } catch (error) {
        //end of try
        console.log("error in queryemp", error)
      }
    },
    //selectedmgrnames,selectedmgremails,selectedreviewersnames,selectedreviewersemails
    //officialDetails.mgrname,officialDetails.mgremail,officialDetails.clientmgrname,officialDetails.clientmgremail
    selectedmgrnames() {
      var tmpselctedarray = this.managers.filter((obj) => obj.name == this.officialDetails.mgrname)
      this.officialDetails.mgremail = tmpselctedarray[0].email
      this.officialDetails.mgrid = tmpselctedarray[0].id

      this.officialDetails.clientmgrname = this.officialDetails.mgrname
      this.officialDetails.clientmgremail = this.officialDetails.mgremail
      this.officialDetails.clientmgrid = this.officialDetails.mgrid

      console.log(
        "from selectedmgrnames ",
        this.officialDetails.mgrname,
        this.officialDetails.mgremail,
        this.officialDetails.mgrid
      )
    },

    selectedmgremails() {
      var tmpselctedarray = this.managers.filter((obj) => obj.email == this.officialDetails.mgremail)
      this.officialDetails.mgrname = tmpselctedarray[0].name
      this.officialDetails.mgrid = tmpselctedarray[0].id

      this.officialDetails.clientmgrname = this.officialDetails.mgrname
      this.officialDetails.clientmgremail = this.officialDetails.mgremail
      this.officialDetails.clientmgrid = this.officialDetails.mgrid
    },
    selectedreviewersnames() {
      var tmpselctedarray = this.reviewers.filter((obj) => obj.name == this.officialDetails.clientmgrname)
      this.officialDetails.clientmgremail = tmpselctedarray[0].email
      this.officialDetails.clientmgrid = tmpselctedarray[0].id
    },

    selectedreviewersemails() {
      var tmpselctedarray = this.reviewers.filter((obj) => obj.email == this.officialDetails.clientmgremail)
      this.officialDetails.clientmgrname = tmpselctedarray[0].name
      this.officialDetails.clientmgrid = tmpselctedarray[0].id
    },

    cancelemp() {
      this.queryemp()
    },
    approveemp() {
      this.approvalStatus = "approved"
      this.saveemp()
    },
    rejectemp() {
      this.approvalStatus = "notapproved"
      this.saveemp()
    },
    saveemp() {
      // this.personalDetails.fullName=this.officialDetails.name+ this.familyDetails.father.name;
      console.log("inside saveemp", this.uid, this.personalDetails.fullName)
      this.spinner = true
      var passvalue = {
        role: this.role,
        domain: this.domain,
      }
      if (this.uid) {
        console.log("inside update emp")
        /*console.log("empid",this.officialDetails.empid);
           console.log("email",this.officialDetails.email);
           console.log("name",this.officialDetails.name);
           console.log("mgrname",this.officialDetails.mgrname);
           console.log("officialDetails",this.officialDetails);
           console.log("personalDetails",this.personalDetails);
           console.log("documentDetails",this.officialDetails);
           console.log("emergencyDetails",this.emergencyDetails);
           console.log("familyDetails",this.familyDetails);
           console.log("approvalStatus",this.approvalStatus);*/

        try {
          empRef.doc(this.uid).set({
            empid: this.officialDetails.empid,
            email: this.officialDetails.email,
            name: this.officialDetails.name,
            mgrname: this.officialDetails.mgrname,
            mgremail: this.officialDetails.mgremail,
            officialDetails: this.officialDetails,
            personalDetails: this.personalDetails,
            documentDetails: this.documentDetails,
            emergencyDetails: this.emergencyDetails,
            familyDetails: this.familyDetails,
            approvalStatus: this.approvalStatus,
            domain: this.domain,
          })
          ;(this.snackbar = true), (this.snackbartext = "your recored has been added/updated")
          console.log(
            "after updated emp record noofficialemail:",
            this.noofficialemail,
            "officialEmail:",
            this.officialDetails.email,
            "HR-ROLE",
            this.hrrole
          )
          if (this.noofficialemail && this.officialDetails.email && this.hrrole) {
            this.updatempdata("personalToOfficial")
            // this.empleavedateailsupdate();
            this.newalertemail("newemail")
          } else if (this.noofficialemail && !this.officialDetails.email && !this.hrrole) {
            this.newalertemail("newempdetailsupdate")
          }
          else if(this.officialemail!=this.officialDetails.email && this.hrrole){
             this.updatempdata("officialToOfficial")
          }

          if (this.role.hradmin) {
            this.$router.push({ name: "hradmin", params: { roles: passvalue } })
          }

          if (this.role.sysadmin) {
            this.$router.push({ name: "sysadmin", params: { roles: passvalue } })
          }
        } catch (error) {
          console.log("Error in updateemp", error)
          this.snackbar = true
          this.snackbartext = "Error in updateemp" + error
        }

        if (this.role.hradmin) {
          this.$router.push({ name: "hradmin", params: { roles: passvalue } })
        }

        if (this.role.sysadmin) {
          this.$router.push({ name: "sysadmin", params: { roles: passvalue } })
        }
      } else {
        console.log("inside add emp")
        try {
          /* 
             await recruitmentRef.add(this.editedItem).then(docRef => {
                        console.log("uid for new record is ",docRef.id)
                          this.editedItem.uid=docRef.id;
                        })
                        .catch(error => console.error("Error adding recruitmentRef: ", error))*/
          empRef
            .add({
              empid: this.officialDetails.empid,
              email: this.officialDetails.email,
              name: this.officialDetails.name,
              mgrname: this.officialDetails.mgrname,
              mgremail: this.officialDetails.mgremail,
              officialDetails: this.officialDetails,
              personalDetails: this.personalDetails,
              documentDetails: this.documentDetails,
              emergencyDetails: this.emergencyDetails,
              familyDetails: this.familyDetails,
              approvalStatus: "pending",
              domain: this.domain,
            })
            .then((docRef) => {
              console.log("uid for new record is ", docRef.id)
              this.uid = docRef.id
            })
            .catch((error) => console.error("Error adding empRef: ", error))
          console.log("one emp added")
          //alert ( "your recored has been added/updated")
          //snackbar,snackbartext
          ;(this.snackbar = true), (this.snackbartext = "your recored has been added/updated")
          this.spinner = false
          console.log(
            "after updated emp record noofficialemail:",
            this.noofficialemail,
            "officialEmail:",
            this.officialDetails.email,
            "HR-ROLE",
            this.hrrole
          )
          if (this.noofficialemail && this.officialDetails.email && this.hrrole) {
            this.updatempdata("personalToOfficial")
          } else if (this.noofficialemail && !this.officialDetails.email && !this.hrrole) {
            this.newalertemail("newempdetailsupdate")
          }

          if (this.role.sysadmin) {
            this.$router.push({ name: "hradmin", params: { roles: passvalue } })
          }

          if (this.role.sysadmin) {
            this.$router.push({ name: "sysadmin", params: { roles: passvalue } })
          }
        } catch (error) {
          console.log("Error in addemp", error)
          this.spinner = false
          this.snackbar = true
          this.snackbartext = "Error while add EMP details" + error
        }
      }
    },
    async empleavedateailsupdate(date) {
      console.log("inside empleavedateailsupdate", date, this.officialDetails.email, this.officialDetails.empid)
      var thisyearleave = 0
      var queryresult = await configgenRef
        .where("domain", "==", this.domain)
        .where("description", "==", "Per Month Leave")
        .get()
      var numberofleavespermonth = parseInt(queryresult.docs[0].data().value)
      console.log("numberofleavespermonth", numberofleavespermonth)
      var empdoj = date
      var currentdate = new Date().toISOString().substr(0, 10)
      var year = currentdate.substring(0, 4)

      var empdoj_year = empdoj.substring(0, 4)
      var empdoj_month = parseInt(empdoj.substring(5, 7))
      var empdoj_date = empdoj.substring(8, 10)

      console.log("empdoj", empdoj_year, empdoj_month, empdoj_date)

      if (empdoj_year < year) {
        thisyearleave = 12 * numberofleavespermonth
      } else {
        var remainingmonth = 12 - empdoj_month
        thisyearleave = 2 * remainingmonth
        if (empdoj_date <= 15) thisyearleave += 2
        else thisyearleave += 1
      }
      queryresult = await leavesummariesRef
        .where("domain", "==", this.domain)
        .where("email", "==", this.officialDetails.email)
        .where("year", "==", year)
        .get()
      console.log("queryresult", queryresult.docs.length)
      console.log("remainingmonth", remainingmonth, thisyearleave)
      if (queryresult.docs.length == 0) {
        leavesummariesRef.add({
          closingbalance: thisyearleave,
          creditedbalance: thisyearleave,
          domain: this.domain,
          email: this.officialDetails.email,
          empid: this.officialDetails.empid,
          encash: 0,
          leavetaken: 0,
          name: this.officialDetails.name,
          openingbalance: 0,
          year: year,
          mgremail: this.officialDetails.mgremail,
        })
      } else {
        var uid = queryresult.docs[0].id
        leavesummariesRef.doc(uid).set({
          closingbalance: thisyearleave,
          creditedbalance: thisyearleave,
          domain: this.domain,
          email: this.officialDetails.email,
          empid: this.officialDetails.empid,
          encash: 0,
          leavetaken: 0,
          name: this.officialDetails.name,
          openingbalance: 0,
          year: year,
          mgremail: this.officialDetails.mgremail,
        })
      }
    },
    async newalertemail(reason) {
      var queryresult = await emailRef.where("domain", "==", this.domain).get()
      if (reason == "newemail") {
        this.mailmsg.to = this.personalDetails.email
        this.mailmsg.subject = "Welcome to " + this.domain + "- new email"
        this.mailmsg.message =
          "Hi, your new email id is: " +
          this.officialDetails.email +
          " , please login in to  portal with new email and verify the details,The new password is:" +
          this.password
        console.log(this.mailmsg)
        this.sendEmail(this.mailmsg)
        ;(this.mailmsg.to = queryresult.docs[0].data().hradminemail),
          (this.mailmsg.subject = "Welcome to " + this.domain + "- new email")
        this.mailmsg.message =
          "Hi, new email id is created:" +
          this.officialDetails.email +
          " , please login in to Portal and approve the details"
      } else if (reason == "newempdetailsupdate") {
        ;(this.mailmsg.to = queryresult.docs[0].data().sysadminemail),
          (this.mailmsg.subject = "SSP- new details update")
        this.mailmsg.message =
          "Hi, the user empid:" +
          this.officialDetails.empid +
          " have updated the details please login in to  portal and assign the new email"
        console.log(this.mailmsg)
        this.sendEmail(this.mailmsg)
      }
      return true
    },

    async updatempdata(type) {
      // this function will create the new user based on the official email and also update the emprole and employee recoreds.
      this.spinner = false
      console.log("inside updatempdata", this.officialDetails.email, this.personalDetails.email)
      /*  var tmppassword=Math.floor(100000 + Math.random() * 900000);
             this.password=tmppassword.toString();*/
      var newUser = {}
      newUser.email = this.officialDetails.email
      if (type == "officialToOfficial") {
        newUser.oldemail = this.officialemail
        this.updateallRecords() ;
      } else {
       newUser.oldemail = this.personalDetails.email
      }
      newUser.empid = this.officialDetails.empid
      newUser.domain = this.domain
     let Ret = await this.updateemail(newUser)
      //let Ret = { status: true }
      console.log("From newUser creation", Ret)
      this.snackbar = true
      this.dialogclose = true
      if (Ret.status == true) {
        this.snackbartext = "User updated successfully"
        this.dialogclose = true
      } else {
        this.snackbartext = Ret.error
      }
    },
    async updateallRecords(){
      console.log("inside updatePDP",this.officialemail)
      var doc = await PDPRef.doc(this.officialemail).get();

      if(doc.exists){
        console.log("updatePDP doc",doc.exists)
          var pdpdata = doc.data();
          var pdparray = pdpdata.pdp;
          console.log("updatePDP pdparray",pdparray.length)
        for (var i = 0; i < pdparray.length; ++i) {
          pdparray[i].email = this.officialDetails.email;
        }
        pdpdata.email = this.officialDetails.email;
         console.log("final pdparray", pdpdata)
        PDPRef.doc(this.officialDetails.email).set({...pdpdata}).then(()=>{
         console.log("pdpd added",this.officialDetails.email)
        /*   PDPRef.doc(this.officialemail).delete().then(()=>{
           console.log("pdpd delelted",this.officialemail)
         }) */
       });
      } // end of PDP update ,
      var queryresultPDP=await PDPRef.where("revieweremail",'==',this.officialemail).get();
      for(var j = 0; j < queryresultPDP.docs.length; ++j){
        PDPRef.doc(queryresultPDP.docs[j].id).update({revieweremail:this.officialDetails.email})
      }

      var queryresult=await usersRef.where("mgremail",'==',this.officialemail).get();
      for( var k = 0;  k < queryresult.docs.length; ++k){
        usersRef.doc(queryresult.docs[k].id).update({mgremail:this.officialDetails.email})
      }

       var queryresultEmp=await empRef.where("mgremail",'==',this.officialemail).get();
      for( var l = 0; l < queryresultEmp.docs.length; ++l){
        var officialDetailsdoc={...queryresultEmp.docs[l].data().officialDetails}
        officialDetailsdoc.mgremail=this.officialDetails.email
        empRef.doc(queryresultEmp.docs[l].id).update({mgremail:this.officialDetails.email,
                                                        officialDetails:{...officialDetailsdoc}})
      }
     // clientmgrname

      var queryresultProfile=await recruitmentRef.where("assingedTo",'==',this.officialemail).get();
      for( var m = 0; m < queryresultProfile.docs.length; ++m){
        recruitmentRef.doc(queryresultProfile.docs[m].id).update({assingedTo:this.officialDetails.email})
      }
      var queryresultEmpClient=await empRef.where("officialDetails.clientmgremail",'==',this.officialemail).get();
      for( var n = 0; n < queryresultEmpClient.docs.length; ++n){
        var officialDetailsdoc1={...queryresultEmpClient.docs[n].data().officialDetails}
        officialDetailsdoc1.clientmgremail=this.officialDetails.email
        empRef.doc(queryresultEmpClient.docs[n].id).update({officialDetails:{...officialDetailsdoc1}})
      }
      //reviewers,reviewersRef
      var idx=this.reviewers.findIndex((obj) => obj.email == this.officialemail);
      if(idx > -1){
        this.reviewers[idx].email=this.officialDetails.email;
        reviewersRef.doc(this.domain).set({
          reviewers: this.reviewers,
        })
      }

       
       /* var pdpdata = pdp.docs[0].data();
        console.log("inside pdpdata ",pdpdata)
        var pdparray = pdpdata.pdp;
        for (var i = 0; i < pdparray.length; ++i) {
          pdparray[i].email = this.officialDetails.email;
        }
        pdparray.email = this.officialDetails.email;
      }*/
     

    },
    async updatenewemailrec() {
      var queryresult = []
      console.log("inside updatenewemailrec", this.officialDetails.empid)
      queryresult = await usersRef
        .where("empid", "==", this.officialDetails.empid)
        .where("domain", "==", this.domain)
        .get()
      if (queryresult.docs.length == 1) {
        await usersRef.doc(queryresult.docs[0].id).update({
          email: this.officialDetails.email,
          tmppassword: this.password,
        })
        await deleteRef.add({
          personalemail: this.personalDetails.email,
          officialemail: this.officialDetails.email,
          time: new Date().toISOString().substr(0, 10),
          status: "notdeleted",
        })
        this.newalertemail("newemail")
      } else {
        console.log("error while query the result")
      }
    },
    deleteImage(img, index) {
      let image = firebaseApp.storage().refFromURL(img)

      this.documentDetails.images.splice(index, 1)

      image
        .delete()
        .then(function () {
          console.log("image deleted")
        })
        .catch(function (error) {
          // Uh-oh, an error occurred!
          console.log("an error occurred", error)
        })
    },

    addAttachment(options, fileDetails) {
      console.log("inside addAttachmentt options", options)
      console.log("inside addAttachment fileDetails", fileDetails)
      this.documentDetails.images.push({ ...fileDetails })
    },
    removeAttachment(options, fileDetails) {
      console.log("inside removeAttachment options", options)
      console.log("inside removeAttachment fileDetails", fileDetails)
      let files = this.documentDetails.images.filter((v) => v.filename !== fileDetails.filename)
      this.documentDetails.images = [...files]
    },

    uploadImage(e) {
      if (e.target.files[0]) {
        let file = e.target.files[0]

        var storageRef = firebaseApp
          .storage()
          .ref("empdocs/" + this.domain + "_" + this.officialDetails.empid + "_" + file.name)

        let uploadTask = storageRef.put(file)

        uploadTask.on(
          "state_changed",
          () => {
            console.log("uploadtask completed")
          },
          (error) => {
            // Handle unsuccessful uploads
            console.log("error in file upload", error)
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...

            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.documentDetails.images.push({ fileurl: downloadURL, filename: file.name })
              console.log("document url is", this.documentDetails.images)
            })
          }
        )
      }
    }, //end of upload image
  }, // end of methods
}
</script>
<style scoped>
.containerclass {
  background-color: rgb(255, 255, 255);
}
.Employee {
  background-color: white;
}
.personalDetails {
  background-color: white;
}
.DocDetails {
  background-color: white;
}
.EmergencyDetails {
  background-color: white;
}
.FamilyDetails {
  background-color: white;
}
.ppimg {
  width: 100px;
  height: 100px;
}
.img-wrapp span.delete-img {
  position: absolute;
  top: -14px;
  left: -2px;
}
.img-wrapp span.delete-img:hover {
  cursor: pointer;
}
</style>
