<template>
  <div>
    <v-file-input
      v-model="files"
      multiple
      counter
      show-size
      clearable
      :label="uploadname"
      :placeholder="placeholder"
      :disabled="disabled"
      
    >
      <template v-slot:selection="{ text }">
        <v-chip small label v-text="text"></v-chip>
      </template>
    </v-file-input>
    <v-btn
      block
      left
      class="my-3"
      :loading="uploading"
      :disabled="disabled || uploadButtonDisable"
      @click="detectFiles()"
    >
      Upload
    </v-btn>
    <template v-if="hasAttachments">
      <p class="text-overline labels--text">Uploaded Attachments</p>
      <Attachment
        v-for="(file, index) in attachments"
        :key="index"
        :file="file"
        :index="index"
        :close="true"
        :color="'primary'"
        @click:close="deleteFile(file)"
      />
    </template>
  </div>
</template>

<script>
import { firebaseApp } from "../../fb"
//import {recruitmentRef,firebaseApp,empRef,jobsRef,configgenRef,authdomain,clientprofilesRef}
import "firebase/storage"
const storage = firebaseApp.storage()
// import { storage } from "@/firebase"
import Attachment from "@/components/common/Attachment.vue"
const storageRef = storage.ref()
export default {
  name: "Upload",
  components: {
    Attachment,
  },
  props: {
    placeholder: {
      type: String,
      default: () => "Click here to Select Files to Attach",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    storagepath: {
      type: String,
      default: () => "default",
    },
    uploadname: {
      type: String,
      default: () => "default",
    },
  },

  data() {
    return {
      files: [],
      progressUpload: 0,
      uploadTask: "",
      uploading: false,
      uploadEnd: false,
      closetrue: true,
    }
  },
  created() {
    console.log("upload created", this.uploadname, this.files)
  },
  computed: {
    uploadButtonDisable() {
      return this.files.length === 0 ? true : false
    },
    hasAttachments() {
      return this.attachments.length > 0 ? true : false
    },
  },
  methods: {
    //detect if files exist
    detectFiles() {
      this.uploading = true
      this.files.forEach((element, index) => {
        this.upload(element, index)
      })
    },
    //upload to cloud storage
    upload(file) {
      let uploadTask = storageRef.child(this.storagepath + file.name).put(file)
      uploadTask.on(
        "state_changed",
        (sp) => {
          this.progressUpload = Math.floor((sp.bytesTransferred / sp.totalBytes) * 100)
        },
        null,
        async () => {
          let downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          this.$emit("addAttachment", this.uploadname, { filename: file.name, fileurl: downloadURL })
          this.uploading = false
          this.files = []
        }
      )
    },
    //delete the file on clicking the x ICON in the file, event is emitted from Attachment component
    async deleteFile(file) {
      console.log("inside deleteFile ", this.storagepath, file.filename)
      let delRef = storageRef.child(this.storagepath + file.filename)
      try {
        await delRef.delete()
      } catch (err) {
        alert(err)
      }
      this.$emit("removeAttachment", this.uploadname, file)
    },
    // if multiple files delete all files one by one in a async manner
    deleteAllFiles() {
      if (this.hasAttachments) {
        this.attachments.forEach(async (file) => {
          await this.deleteFile(file)
        })
      }
    },
  },
}
</script>
