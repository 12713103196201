import axios from "axios"
import { usersRef, userconfig, authdomain, sendmailurl, updateemailurl } from "../fb"

//raja@venzoconsulting.com
export const updateemailMixin = {
  data: function () {
    return {
      retobj: { uid: "", status: "", userconfig: "", error: "" },
    }
  },

  methods: {
    async updateemail(body) {
      var tmppassword = Math.floor(100000 + Math.random() * 900000)
      body.passwd = tmppassword.toString()
      console.log("inside updateUser", body)

      var Ret = await this.updatecallpost(body)
      console.log("addUser Ret ", Ret)

      console.log("addUser retobj ", this.retobj)
      return this.retobj
    }, // end of addUser method
    async updatecallpost(body) {
      try {
        await axios.post(updateemailurl, body)

        await this.updateemailData(body)
        console.log("callpost retobj ", this.retobj)
        return
      } catch (err) {
        console.log(err)
        this.retobj.status = false
        return
      }
    }, // end of callpost
    async updateemailData(body) {
      try {
        var retobj = {}
        console.log("inside updateemailData", body)
        var queryresult = await usersRef.where("email", "==", body.oldemail).get()
        console.log(" after query", queryresult.docs[0].id)
        await usersRef.doc(queryresult.docs[0].id).update({ email: body.email })
        this.updatemailfun(body)
        this.retobj.status = true
        this.retobj.uid = queryresult.docs[0].id
        this.retobj.userconfig = userconfig
        console.log("updateemailData retobj ", retobj)
        return

        /* await usersRef.where("email","==",body.oldemail).get().then(docRef => {
            console.log("inside update uid",docRef.id)
            usersRef.doc(docRef.id).update({email:body.email});
            this.mailfun(body);
            this.retobj.status=true;
            this.retobj.uid=docRef.id;
            this.retobj.userconfig=userconfig;
            console.log("updateemailData retobj ",retobj)
            return ;

          });

        await usersRef.where('email','==',body.oldemail).update({
          email:body.email
          }).then(docRef => {
            this.mailfun(body);
            this.retobj.status=true;
            this.retobj.uid=docRef.id;
            this.retobj.userconfig=userconfig;
            console.log("updateemailData retobj ",retobj)
            return ;

          });*/
      } catch (err) {
        this.retobj.status = false
        this.retobj.error = err
        return
      }
    }, // end of addTableData

    async updatemailfun(body) {
      console.log("inside mailfun", body)
      var mailmsg = {}
      mailmsg.to = body.email
      mailmsg.subject = "Welcome to " + body.domain + " new employee id"
      mailmsg.message =
        "Hi, your employee id is: " +
        body.empid +
        ", please login in to " +
        body.domain +
        " portal and update the details."
      mailmsg.message = mailmsg.message + " The user name is your email address and password is:" + body.passwd + ". "
      mailmsg.message = mailmsg.message + '<a href="' + authdomain + '">Click here to access the portal</a>'
      console.log(mailmsg)
      // this.sendEmail(mailmsg);
      await axios
        .post(sendmailurl, mailmsg)
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    /* async addUser(body) {
      console.log("inside addUser")
      await axios
        .post("https://us-central1-venzoadmindev.cloudfunctions.net/addUser", body)
        .then((res) => {
          console.log(res)
          try{
            usersRef.add({
            empid:body.empid,
            email:body.email,
            status:body.status,
            role:body.newroles,
            name:body.name,
            mgrName:body.mgrName,
            mgremail:body.mgremail,
            domain:body.domain,
          
            createdOn:new Date().toISOString().substr(0, 10),
            userconfig:body.userconfig,
            }).then(docRef => {
             console.log(docRef)
             return true
            })
          }
          catch (err)
          {
            console.log(err)
            return err
          }

          
        })
        .catch((err) => {
          console.log(err)
          return err
        })
    },*/ // end of add user
  },
}
//sendMail
